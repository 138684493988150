import Chip from '@mui/material/Chip'
import React, { useEffect, useState } from 'react'

const ChipTransition: React.FC<{ tags: tag[] }> = ({ tags }) => {
  const [currentTag, setCurrentTag] = useState<number>(0)

  useEffect(() => {
    if (tags?.length < 1) return
    const timer = setInterval(() => {
      setCurrentTag(current => {
        if (current === tags?.length - 1) {
          return 0
        }
        return current + 1
      })
    }, 2000)
    return () => clearTimeout(timer)
  }, [tags])

  if (!Boolean(tags?.length)) return

  const tag = tags[currentTag]
  return <>{Boolean(tag) && <Chip size="small" color={tag.highlighted ? 'secondary' : 'primary'} label={tag.text} />}</>
}

export default ChipTransition
