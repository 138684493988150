import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import CloseIcon from '@mui/icons-material/Close'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout'
import { useMediaQuery } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { useRouter } from 'next/router'
import React, { useState } from 'react'
import { useRecoilState } from 'recoil'

import ComparaCarIcon from '@comparacar/components/src/atoms/icons/brands/SubscriptionComparaCar'
import basketState from '@comparacar/components/src/state/basketState'

interface iOfferButtonProps {
  data: ResultCard
  origin?: string
}

const OfferButton: React.FC<iOfferButtonProps> = ({ data, origin }) => {
  const router = useRouter()
  const [showAskBasket, setShowAskBasket] = useState(false)
  const [basket, setBasketItem] = useRecoilState(basketState)
  const isDesktop = useMediaQuery('(min-width:900px)')

  const isAlreadyOnBasket = basket.some(item => item.id === data.id)
  const isEmptyBasket = !Boolean(basket.length)

  const handleCloseAskBasket = () => {
    setShowAskBasket(false)
  }

  return (
    <>
      {!data.showPreLinkOffer && data.type === 'ADVERTISEMENT' ? (
        <Button
          data-cy="offerButton"
          variant="contained"
          onClick={() => {
            window.open(data.link, '_blank')
          }}
          endIcon={<ArrowForwardIcon />}
          fullWidth
        >
          Ver oferta
        </Button>
      ) : (
        <Button
          disabled={isAlreadyOnBasket}
          data-cy="offerButton"
          onClick={() => {
            if (isEmptyBasket) {
              setShowAskBasket(true)
            } else {
              setBasketItem(currentState => [...currentState, data])
            }
          }}
          startIcon={isEmptyBasket ? <LocalOfferIcon /> : <AddShoppingCartIcon />}
          fullWidth
          variant="contained"
        >
          {isAlreadyOnBasket ? 'Adicionado' : isEmptyBasket ? 'Solicitar proposta' : 'Adicionar'}
        </Button>
      )}
      <Dialog open={showAskBasket} onClose={handleCloseAskBasket} maxWidth="sm" fullWidth fullScreen={!isDesktop}>
        <DialogContent>
          <Box sx={{ position: 'absolute', right: '16px', top: '16px' }}>
            <IconButton sx={{ color: 'text.primary' }} onClick={handleCloseAskBasket}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            justifyContent="center"
            alignItems="center"
            display="flex"
            sx={{
              '& > *': {
                mr: 2
              }
            }}
          >
            <ComparaCarIcon black width="176" height="32" />
          </Box>
          <Box
            justifyContent="center"
            alignItems="center"
            display="flex"
            flexDirection="column"
            sx={{
              mb: 4,
              '& > *': {
                mr: 2
              }
            }}
          >
            <Typography variant="h6" textAlign="center" marginY={3}>
              Gostaria de seguir com a proposta ou selecionar mais ofertas?
            </Typography>
          </Box>
          <Box>
            <Button
              data-cy="followUp"
              disabled={isAlreadyOnBasket}
              onClick={() => {
                setBasketItem(currentState => [...currentState, data])
                router.push({
                  pathname: '/assinar/lead',
                  query: {
                    origin: origin || data?.brand || ' Fale agora'
                  }
                })
                handleCloseAskBasket()
              }}
              startIcon={<ShoppingCartCheckoutIcon />}
              fullWidth
              variant="contained"
            >
              Prosseguir com a proposta
            </Button>
            <Button
              sx={{
                '.Mui-disabled': {
                  backgroundColor: 'rgb(226 227 228)'
                }
              }}
              data-cy="addMoreOffers"
              disabled={isAlreadyOnBasket}
              onClick={() => {
                setBasketItem(currentState => [...currentState, data])
                handleCloseAskBasket()
              }}
              startIcon={<AddShoppingCartIcon />}
              fullWidth
              variant="text"
            >
              Selecionar mais ofertas
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default OfferButton
